<template>
  <div>
    <div>
      <label>
        Recipient Name
      </label>
      <h5>{{ validatedData.payoutForm.recipient_name }}</h5>

    </div>
    <div>
      <label>
        Amount
      </label>

      <h5><vue-numeric
        :value="validatedData.payoutForm.amount"
        :precision="2"
        :currency="myCurrency"
        read-only
      /></h5>

    </div>
    <div class="border p-1 my-1">
      <h5><strong>Validated Account Info</strong></h5>
      <b-row>
        <b-col>
          <label>
            Creditor Name
          </label>
          <h5>{{ validatedData.Result.CreditorName }}</h5>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <label>
            Bank
          </label>
          <h5>{{ validatedData.Result.OrgnThirdPartyBankBICCode }}</h5>
        </b-col>

        <b-col>
          <label>
            Account Number
          </label>
          <h5>{{ validatedData.Result.OrgnThirdPartyAccountNo }}</h5>
        </b-col>
      </b-row>

    </div>
    <div>
      <label>
        Recipient Reference
      </label>
      <h5>{{ validatedData.payoutForm.recipient_reference }}</h5>

    </div>
    <div>
      <label>
        Payment Description
      </label>
      <h5>{{ validatedData.payoutForm.payment_description }}</h5>

    </div>

    <div class="d-flex justify-content-end mt-3">
      <b-button @click="cancelModal">
        Cancel
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="confirmPayout"
      >
        Make Payout
      </b-button>
    </div>
  </div>
</template>

<script>

const LEANX_PAYOUT_POOL_ID_PROD = 'VA-CC408F-161442426909-PAYOUT'

const LEANX_PAYOUT_POOL_ID_STAG = 'VA-E3FC49-095928014543-PAYOUT'

export default {
  components: {},
  props: {
    validatedData: {
      type: Object,
      default: () => (
        {
          Success: false,
          Result: {
            OrgnThirdPartyBankBICCode: '',
            OrgnThirdPartyAccountNo: '',
            CreditorName: '',
          },
          payoutForm: {
            client_identifier: 'LEANPAY',
            virtual_pool_reference: '',
            payout_service_id: null,
            recipient_name: '',
            amount: '',
            client_redirect_url: 'https://api.leanx.io/pay/thankyou?additional_message=xx&bill_id=xx&extra_param=leanpay-extraneous-param',
            client_callback_url: 'https://api.leanx.io/api/v1/callback-url/call-invoice-payout-encrypt',
            third_party_account_no: '',
            recipient_reference: '',
            payment_description: '',
            verification_channel_id: null,
          },
        }
      ),
    },
  },
  data() {
    return {
      form: {
        name: 'Confirm',
      },
    }
  },
  methods: {
    async cancelModal() {
      this.$emit('cancel-payout-clicked', this.form)
    },
    async confirmPayout() {
      if (this.IS_PRODUCTION) {
        this.validatedData.payoutForm.virtual_pool_reference = LEANX_PAYOUT_POOL_ID_PROD
        this.validatedData.payoutForm.client_redirect_url = 'https://api.leanx.io/pay/thankyou?additional_message=xx&bill_id=xx&extra_param=leanpay-extraneous-param'
        this.validatedData.payoutForm.client_callback_url = 'https://api.leanx.io/api/v1/callback-url/call-invoice-payout-encrypt'
      }
      if (this.IS_STAGING) {
        this.validatedData.payoutForm.virtual_pool_reference = LEANX_PAYOUT_POOL_ID_STAG
        this.validatedData.payoutForm.client_redirect_url = 'https://stag-api.leanpay.my/pay/thankyou?additional_message=xx&bill_id=xx&extra_param=leanpay-extraneous-param'
        this.validatedData.payoutForm.client_callback_url = 'https://stag-api.leanpay.my/api/v1/callback-url/call-invoice-payout-encrypt'
      }

      const payload = {
        ...this.validatedData.payoutForm,
        payout_service_id: this.validatedData.payoutForm.payout_service_id,
      }

      await this.$store.dispatch('payout/createPayoutRecordAdmin', payload).then(response => {
        if (response.data.response_code === 2000) {
          this.$swal.fire({
            title: 'Success',
            text: 'Payout Successful',
            icon: 'success',
          })
          this.$emit('confirm-payout-clicked', true)
        } else {
          this.$swal.fire({
            title: `Failed ${response.data.response_code}`,
            text: response.data.description,
            icon: 'error',
          })
        }
      })
    },
  },
}
</script>

<style>

</style>
