<template>
  <div class="receipt-background">
    <vue-html2pdf
      ref="html2Pdf"
      :show-layout="false"
      :float-layout="false"
      :enable-download="true"
      :preview-modal="true"
      :paginate-elements-by-height="1600"
      :filename="receiptObject.data.invoice_no"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-orientation="landscape"
      pdf-content-width="100%"
      @progress="onProgress($event)"
      @beforeDownload="beforeDownload($event)"
    >

      <section slot="pdf-content">
        <template v-if="isValidJSON(receiptData.callback_data)">

          <div class="px-3 py-3 container my-1">
            <template v-if="receiptObject && receiptObject.isParsable">
              <div class=" card-background p-2 rounded">
                <div class="d-flex justify-content-between">
                  <h3 class="font-weight-bolder mb-3">
                    Transaction Status: {{ payoutReceiptStatus }}
                  </h3>
                  <feather-icon
                    style="color: #9095a8; stroke-width: 4px"
                    icon="ChevronRightIcon"
                    size="30"
                  />
                </div>
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex  align-items-baseline">
                    <h5 class="font-weight-bolder geomanistRegular">
                      Creation Date:
                    </h5>
                    <h4 class="font-weight-bolder pl-1">
                      {{ transformDate(receiptObject.data.payout_payment.item.updated_at, false, 'dd LLL yyyy TT') }}
                    </h4>
                  </div>
                  <b-img
                    :src="auditTrailIcon"
                    height="28"
                  />
                </div>

              </div>

              <div class="mt-1 card-background rounded ">
                <div class="d-flex">
                  <div class="flex-even">
                    <div class="from-header-background">
                      <h4 class="font-weight-bolder mb-0 px-3 py-2">
                        From
                      </h4>
                    </div>
                    <div class="py-4 px-3">
                      <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                        Your Account
                      </h5>
                      <h3 class=" font-weight-bolder mb-2">
                        {{ LeanisAccountNumber }} - <span class="mr-1">{{ Currency }}</span> {{ LeanisDisplayName }}
                      </h3>

                      <div class="d-flex mb-2">
                        <div class="flex-even">

                          <h5 class="font-weight-bolder geomanistRegular mb-0 label2">
                            Value Date
                          </h5>
                          <h5 class="font-weight-bolder">
                            {{ transformDate(receiptObject.data.payout_payment.item.updated_at, false, 'dd LLL yyyy') }}
                          </h5>
                        </div>
                        <div class="flex-even">
                          <h5 class="font-weight-bolder geomanistRegular mb-1 label2">
                            Amount
                          </h5>
                          <h5 class="font-weight-bolder ">
                            <vue-numeric
                              read-only-class="mr-1"
                              read-only
                              separator=","
                              :precision="2"
                              :value="TransactionAmount"
                            />{{ Currency }}
                          </h5>
                        </div>
                      </div>
                      <div class="pb-1 pr-2 pl-2 pt-2 bg-same-day rounded">
                        <div class="d-flex">
                          <span class="font-weight-bolder text-success"><feather-icon
                            icon="CheckIcon"

                            style="stroke-width: 5px"
                            size="20"
                          /></span> <h4 class="ml-1 font-weight-bolder geomanistRegular mb-0 mb-2">
                            Same day payment
                          </h4>
                        </div>
                        <h5 class="font-weight-bolder mb-0 geomanistRegular mb-0 label">
                          Value time
                        </h5>
                        <h5 class="mb-0 font-weight-bolder">
                          {{ transformDate(receiptObject.data.payout_payment.item.updated_at, false, 'dd LLL yyyy') }} {{ TxnType }}
                        </h5>
                      </div>

                    </div>
                  </div>
                  <div class="flex-even">

                    <div class="to-header-background">
                      <h4 class="font-weight-bolder mb-0 pr-4 pl-2 py-2">
                        To
                      </h4>
                    </div>
                    <div class="pr-4 pl-2 py-4">
                      <div class="mb-2">
                        <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                          Transfer Type
                        </h5>
                        <h3 class="font-weight-bolder">
                          {{ TransferType }}
                        </h3>
                      </div>
                      <div class="mb-2">
                        <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                          Beneficiary Bank Name</h5>
                        <h3 class="font-weight-bolder">
                          {{ translateSwiftCode(BeneficiaryBankName) }}
                        </h3>
                      </div>
                      <div class="mb-2">
                        <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                          Beneficiary Account No.</h5>
                        <h3 class="font-weight-bolder">
                          {{ BeneficiaryAccountNo }}
                        </h3>
                      </div>
                      <div class="mb-2">
                        <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                          Beneficiary Name</h5>
                        <h3 class="font-weight-bolder">
                          {{ BeneficiaryDisplayName }}
                        </h3>
                      </div>
                      <div class="d-flex mb-2">

                        <div class="flex-even">
                          <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                            Recipient's Reference</h5>
                          <h3 class="font-weight-bolder">
                            {{ RecipientsReference }}
                          </h3>
                        </div>
                        <div class="flex-even">
                          <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                            Other Payment Details</h5>
                          <h3 class="font-weight-bolder">
                            {{ RecipientsOtherPaymentDetails }}
                          </h3>
                        </div>
                      </div>

                      <div class="mb-2">
                        <h5 class="font-weight-bolder geomanistRegular mb-0 label">
                          PayNet Reference No.</h5>
                        <h3 class="font-weight-bolder">
                          {{ PayNetReferenceNo }}
                        </h3>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </template>

            <template v-else>
              <div class=" card-background p-2 rounded">
                <h3>Cannot Create Payout Receipt</h3>
                <p>Callback object not parseable</p>
              </div>
            </template>
          </div>
        </template>

        <template v-else>
          RECEIPT UNAVAILABLE
        </template>
      </section>

    </vue-html2pdf>

    <!--    <div class="p-2 default-font">-->
    <!--      <b-button-->
    <!--        class="mb-2"-->
    <!--        block-->
    <!--        variant="primary"-->
    <!--        @click="generateReport"-->
    <!--      >-->
    <!--        Print-->
    <!--      </b-button>-->
    <!--    </div>-->

  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'
import humaniseMixin from '@/common/humanise.mixin'

export default {
  components: { VueHtml2pdf },
  mixins: [humaniseMixin],
  props: {
    receiptData: {
      type: Object,
      default: () => {},
      required: true,
    },
  },
  data() {
    return {
      auditTrailIcon: require('@/assets/leanpay/auditTrailIcon.png'),
    }
  },
  computed: {
    LeanisAccountNumber() {
      if (this.receiptObject.data.bank_response.Result.FromAccountNo) {
        return this.receiptObject.data.bank_response.Result.FromAccountNo
      } return '7901106248'
    },
    LeanisDisplayName() {
      return 'LEANIS SOLUTIONS SDN. BHD.'
    },
    TxnType() {
      return 'IMMEDIATE'
    },
    Currency() {
      if (this.receiptObject.data.bank_response.Result.OrgnFromAccountCurrency) {
        return this.receiptObject.data.bank_response.Result.OrgnFromAccountCurrency
      } return this.currency
    },
    BeneficiaryDisplayName() {
      if (this.receiptObject.data.bank_response.Result.DisplayName) {
        return this.receiptObject.data.bank_response.Result.DisplayName
      } return this.currency
    },
    BeneficiaryBankName() {
      if (this.receiptObject.data.bank_response.Result.OrgnThirdPartyBankBICCode) {
        return this.receiptObject.data.bank_response.Result.OrgnThirdPartyBankBICCode
      } return this.currency
    },
    RecipientsReference() {
      if (this.receiptObject.data.bank_response.Result.OrgnRecipientReference) {
        return this.receiptObject.data.bank_response.Result.OrgnRecipientReference
      } return '-'
    },
    RecipientsOtherPaymentDetails() {
      return '-'
    },
    BeneficiaryAccountNo() {
      if (this.receiptObject.data.bank_response.Result.OrgnThirdPartyAccountNo) {
        return this.receiptObject.data.bank_response.Result.OrgnThirdPartyAccountNo
      } return this.currency
    },
    TransactionAmount() {
      if (this.receiptObject.data.bank_response.Result.OrgnTransactionAmount) {
        return this.receiptObject.data.bank_response.Result.OrgnTransactionAmount
      } return 0
    },
    PayNetReferenceNo() {
      if (this.receiptObject.data.bank_response.Result.BizMsgID) {
        return this.receiptObject.data.bank_response.Result.BizMsgID
      } return 0
    },
    TransferType() {
      return 'Fund Transfer'
    },
    payoutReceiptStatus() {
      if (this.receiptObject.data.bank_response.Success) {
        return 'Successful'
      } return 'Failed'
    },
    receiptObject() {
      const cb = this.receiptData
      if (JSON.parse(cb.callback_data).output && this.isValidJSON(cb.callback_data)) {
        return {
          isParsable: true,
          data: JSON.parse(cb.callback_data).output,
        }
      } return {
        isParsable: false,
        data: {},
      }
    },
  },
  methods: {
    translateSwiftCode(str) {
      let bankName = ''
      switch (str) {
        case 'MBBEMYKL':
          bankName = 'MAYBANK BERHAD'
          break

        case 'UOVBMYKL':
          bankName = ' UOB Bank'
          break

        case 'PHBMMYKL':
          bankName = 'Affin Bank'
          break

        case 'AGOBMYKL':
          bankName = 'Agrobank'
          break

        case 'RJHIMYKL':
          bankName = 'Al Rajhi Bank'
          break

        case 'MFBBMYKL':
          bankName = 'Alliance Bank'
          break

        case 'ARBKMYKL':
          bankName = 'Ambank'
          break

        case 'BIMBMYKL':
          bankName = 'Bank Islam'
          break

        case 'BKRMMYKL':
          bankName = 'Bank Kerjasama Rakyat'
          break

        case 'BMMBMYKL':
          bankName = 'Bank Muamalat'
          break

        case 'BOFAMY2X':
          bankName = 'Bank of America'
          break

        case 'BKCHMYKL':
          bankName = 'Bank of China'
          break

        case 'BSNAMYK1':
          bankName = 'Bank Simpanan Nasional'
          break

        case 'BNPAMYKL':
          bankName = 'BNP Paribas'
          break

        case 'PCBCMYKL':
          bankName = 'China Construction Bank'
          break

        case 'CIBBMYKL':
          bankName = 'CIMB Bank'
          break

        case 'DEUTMYKL':
          bankName = 'Deutsche Bank'
          break

        case 'HLBBMYKL':
          bankName = 'Hong Leong Bank'
          break

        case 'HBMBMYKL':
          bankName = 'HSBC Bank'
          break

        case 'CHASMYKX':
          bankName = 'JP Morgan Chase'
          break

        case 'KFHOMYKL':
          bankName = 'Kuwait Finance House'
          break

        case 'AFBQMYKL':
          bankName = 'MBSB Bank Berhad '
          break

        case 'MHCBMYKA':
          bankName = 'Mizuho Bank'
          break

        case 'BOTKMYKX':
          bankName = 'MUFG bank'
          break

        case 'OCBCMYKL':
          bankName = 'OCBC Bank'
          break

        case 'PBBEMYKL':
          bankName = 'Public Bank'
          break

        case 'RHBBMYKL':
          bankName = 'RHB Bank'
          break

        case 'SCBLMYKX':
          bankName = 'Standard Chartered Bank'
          break

        case 'SMBCMYKL':
          bankName = 'Sumitomo Mitsui Banking Corporation'
          break

        case 'TNGDMYNB':
          bankName = 'TNG Digital (TouchNGo)'
          break

        case 'FNXSMYNB':
          bankName = 'Finexus Cards Sdn Bhd'
          break

        case 'BGPYMYNB':
          bankName = 'BigPay Malaysia Sdn Bhd'
          break

        case 'BOSTMYNB':
          bankName = 'Axiata Digital Ecode Sdn Bhd - Boost '
          break

        case 'ARPYMYNB':
          bankName = 'Shopee'
          break

        case 'MASBMYNB':
          bankName = 'Merchantrade Asia Sdn Bhd '
          break

        case 'GXSPMYKL':
          bankName = 'SGX Bank Berhad '
          break

        case 'BOBEMYK2':
          bankName = 'Boost Bank Berhad'
          break

        case 'ACDBMYK2':
          bankName = 'AEON Bank (M) Berhad'
          break

        default:
          bankName = str
          break
      }

      return bankName
    },
    isValidJSON(str) {
      try {
        JSON.parse(str)
        console.log(true)
        return true
      } catch (e) {
        console.log(false)
        return false
      }
    },

    generateReport() {
      // this.hideWhenIdle = false
      this.$refs.html2Pdf.generatePdf()
      // this.hideWhenIdle = true
    },

    onProgress(event) {
      console.log(event)
      this.hideWhenIdle = false
      if (event === 100) {
        this.hideWhenIdle = true
      }
    },
    beforeDownload(event) {
      console.log('beforeDownload', event)
    },
  },
}
</script>

<style lang="scss" scoped>

@font-face {
  font-family: 'geomanistregular';
  src: url('/fonts/geomanist-regular-webfont.eot');
  src: url('/fonts/geomanist-regular-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geomanist-regular-webfont.woff2') format('woff2'),
  url('/fonts/geomanist-regular-webfont.woff') format('woff'),
  url('/fonts/geomanist-regular-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geomanistbook';
  src: url('/fonts/geomanist-book-webfont.eot');
  src: url('/fonts/geomanist-book-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geomanist-book-webfont.woff2') format('woff2'),
  url('/fonts/geomanist-book-webfont.woff') format('woff'),
  url('/fonts/geomanist-book-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'geomanistmedium';
  src: url('/fonts/geomanist-medium-webfont.eot');
  src: url('/fonts/geomanist-medium-webfont.eot?#iefix') format('embedded-opentype'),
  url('/fonts/geomanist-medium-webfont.woff2') format('woff2'),
  url('/fonts/geomanist-medium-webfont.woff') format('woff'),
  url('/fonts/geomanist-medium-webfont.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.geomanistRegular {
  font-family: 'geomanistregular',sans-serif;
  &.label { margin-bottom: 3px !important;}
  &.label2 { margin-bottom: 6px !important;}
}

.receipt-background { font-family: 'geomanistbook',sans-serif; background-color: #f1f4f9; letter-spacing: 1px;}
.from-header-background { background-color: #343c53; border-top-left-radius: 0.357rem !important;
  h4 { color: #fff}
}
.to-header-background { background-color: #616d87; border-top-right-radius: 0.357rem !important;
  h4 { color: #fff}
}
.card-background { background-color: #FFF;}
.bg-same-day { background-color: #f1f5f8;}
.flex-even {
  flex: 1;
}
.btn-audit-trail {
  .win7-btn {
    margin-right: 10px;
    font-weight: bold;
    border-radius: 3px;
    border: 1px solid #ddd;
    padding: 3px 15px 3px 15px;
    background: #f2f2f2;
    background: -moz-linear-gradient(top, #f2f2f2 0%, #ebebeb 42%, #dddddd 47%, #cfcfcf 100%);
    background: -webkit-linear-gradient(top, #f2f2f2 0%, #ebebeb 42%, #dddddd 47%, #cfcfcf 100%);
    background: linear-gradient(to bottom, #f2f2f2 0%, #ebebeb 42%, #dddddd 47%, #cfcfcf 100%);
    //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#f2f2f2', endColorstr='#cfcfcf', GradientType=0);
    transition: all 0.1s ease-in;
    //border: 1px solid #707070;
  }

  .win7-btn:hover,
  .win7-btn:focus {
    outline: 0;
    background: #eaf6fd;
    background: -moz-linear-gradient(top, #eaf6fd 0%, #d9f0fc 42%, #bee6fd 47%, #bce5fc 58%, #a7d9f5 100%);
    background: -webkit-linear-gradient(top, #eaf6fd 0%, #d9f0fc 42%, #bee6fd 47%, #bce5fc 58%, #a7d9f5 100%);
    background: linear-gradient(to bottom, #eaf6fd 0%, #d9f0fc 42%, #bee6fd 47%, #bce5fc 58%, #a7d9f5 100%);
    //filter: progid: DXImageTransform.Microsoft.gradient( startColorstr='#eaf6fd', endColorstr='#a7d9f5', GradientType=0);
    border: 1px solid #3C7FB1;
    box-shadow: 0 0 3px #A7D9F5;
    -o-box-shadow: 0 0 3px #A7D9F5;
    -webkit-box-shadow: 0 0 3px #A7D9F5;
    //-moz-box-shadow: 0 0 3px #A7D9F5;
  }

  .win7-btn:active {
    box-shadow: inset 0 -1px 6px rgba(0, 0, 0, 0.2), inset 0 -0.7em #BEE6FD, 0 0 3px #A7D9F5;
    -o-box-shadow: inset 0 -1px 6px rgba(0, 0, 0, 0.2), inset 0 -0.7em #BEE6FD, 0 0 3px #A7D9F5;
    -webkit-box-shadow: inset 0 -1px 6px rgba(0, 0, 0, 0.2), inset 0 -0.7em #BEE6FD, 0 0 3px #A7D9F5;
    -moz-box-shadow: inset 0 -1px 6px rgba(0, 0, 0, 0.2), inset 0 -0.7em #BEE6FD, 0 0 3px #A7D9F5;
  }
}

</style>
