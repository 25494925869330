const Config = {
  datatableFeatures: {
    extraButtons: [
      // {
      //   buttonLabel: 'Admin Payout',
      //   buttonRef: 'adminPayoutButtonRef',
      //   variant: 'primary',
      // },
    ],
    buttons: {
      createButton: false,
      createButtonLabel: 'Create New Payout',
      createButtonRoute: 'administration-payout-new',
      refresh: true,
    },
    tableStatusFilterButton: [
      {
        buttonLabel: 'All',
        buttonRef: 'allPayoutFilter',
        active: true,
      },
      {
        buttonLabel: 'Pending',
        buttonRef: 'pendingPayoutFilter',
        active: false,
      },
      {
        buttonLabel: 'Success',
        buttonRef: 'successPayoutFilter',
        active: false,
      },
      {
        buttonLabel: 'Pending Approval',
        buttonRef: 'pendingApprovalPayoutFilter',
        active: false,
      },
      {
        buttonLabel: 'Failed',
        buttonRef: 'failedPayoutFilter',
        active: false,
      },
    ],
    tableActionField: false,
    datepicker: false,
    showDTDetails: true,
    perPageDropdown: true,
    searchField: false,
    filter: false,
    pagination: true,
    search: true,
  },
  searchOptsDropdown: [
    { value: 'account_id', text: 'Account ID' },
    { value: 'transaction_invoice_no', text: 'Invoice No' },
    { value: 'recipient_name', text: 'Recipient Name' },
    { value: 'payout_account_number', text: 'Recipient Account No' },
    { value: 'value', text: 'Amount' },
    { value: 'transaction_status', text: 'TXN Status' },
    { value: 'own_settlement_invoice_no', text: 'Self Settlement Invoice #' },
    // { value: 'external_invoice_ref', text: 'External invoice Ref' },
  ],
  tableData() {
    return {
      tableAction: [
        // CUSTOM
        {
          enable: true, param: 'id', btnLabel: 'Callback', action: 'custom', btnIcon: '', btnOnly: false, variant: 'secondary', size: 'sm', iconSize: '12', actionParam: 'triggerCallbackData', disableCondition: 'transaction_status:x',
        //   enable: true, param: 'id', btnLabel: 'View Raw', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'viewRawData',
        },
        {
          enable: true, param: 'id', btnLabel: 'View Raw', action: 'custom', btnIcon: '', btnOnly: false, variant: 'primary', size: 'sm', iconSize: '12', actionParam: 'viewRawData', disableCondition: 'commission_exist:x',
        //   enable: true, param: 'id', btnLabel: 'View Raw', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'viewRawData',
        },
        {
          enable: true, param: 'id', btnLabel: 'Check Bank', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'checkBankAccount', disableCondition: 'commission_exist:x',
        //   enable: true, param: 'id', btnLabel: 'View Raw', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'viewRawData',
        },
        // {
        //   enable: true, param: 'id', btnLabel: 'Generate Receipt', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'generateReceipt', disableCondition: 'commission_exist:x',
        // //   enable: true, param: 'id', btnLabel: 'View Raw', action: 'custom', btnIcon: '', btnOnly: false, variant: 'warning', size: 'sm', iconSize: '12', actionParam: 'viewRawData',
        // },
        // {
        //   enable: true, param: 'id', btnLabel: 'Update Status', action: 'custom', btnIcon: '', btnOnly: false, variant: 'info', size: 'sm', iconSize: '12', actionParam: 'openUpdatePayoutStatus',
        // },

        // DEFAULT
        {
          enable: false, param: 'id', btnLabel: 'View', action: 'view', btnIcon: 'SearchIcon', btnOnly: true, variant: 'primary', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Edit', action: 'edit', btnIcon: 'Edit2Icon', btnOnly: true, variant: 'info', size: 'sm', iconSize: '12',
        },
        {
          enable: false, param: 'id', btnLabel: 'Remove', action: 'remove', btnIcon: 'Trash2Icon', btnOnly: true, variant: 'danger', size: 'sm', iconSize: '12',
        },
      ],
      tableHeader: [
        {
          key: 'show_details', label: '', sortable: false, visible: true, type: 'showDetails', class: 'show-details col-width',
        },
        {
          key: 'account_id', label: 'Account ID', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'account_name', label: 'Account Name', sortable: true, visible: false, type: 'string',
        },
        {
          key: 'name', label: 'Name', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'merchant_invoice_id', label: 'Payout Pool ID', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'own_settlement_invoice_no', label: 'Self Settlement Invoice #', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'transaction_invoice_no', label: 'Invoice No', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'external_invoice_ref', label: 'Ext Invoice Ref', sortable: true, visible: false, type: 'string', showInDetails: true,
        },
        {
          key: 'recipient_name', label: 'Paid to (Name)', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'own_settlement_account_number', label: 'Paid to (Account)', sortable: true, visible: true, type: 'payout_check', showInDetails: true,
        },
        {
          key: 'value', label: 'Amount', sortable: true, visible: true, type: 'monetary', showInDetails: true,
        },

        {
          key: 'transaction_fee', label: 'TXN Fee', sortable: true, visible: true, type: 'monetary', showInDetails: true,
        },
        {
          key: 'transaction_status', label: 'Transaction Status', sortable: true, visible: true, type: 'string', showInDetails: true,
        },
        {
          key: 'data', label: 'Data', sortable: true, visible: false, showInDetails: true, type: 'dataJSON',
        },
        {
          key: 'callback_data', label: 'CallBack Data', sortable: true, visible: false, showInDetails: true, type: 'bankResponse_wrap',
        },

        {
          key: 'data.client_data', label: 'Extracted Client Data', sortable: true, visible: false, showInDetails: true, type: 'extractedClientDataJSON',
        },
        {
          key: 'created_at', label: 'Created At', sortable: true, visible: true, type: 'date', showInDetails: true,
        },
        {
          key: 'admin_action', label: 'Admin', sortable: true, visible: true,
        },
        {
          key: 'actions', label: 'action', sortable: true, visible: true,
        },
      ],
    }
  },
}

export default Config
