<template>
  <div>
    <b-form-group :class="[{ 'has-error': v$.form.client_identifier.$error }]">
      <label>Client Identifier</label>
      <b-form-input
        v-model="form.client_identifier"
        placeholder="Client Identifier"
        disabled
      />
      <template v-for="(error, idx) in v$.form.client_identifier.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>
    <b-form-group :class="[{ 'has-error': v$.form.verification_channel_id.$error }]">
      <label>Bank</label>
      <v-select
        v-model="form.verification_channel_id"
        :reduce="verification_channel_id => verification_channel_id.payout_service_id"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="name"
        placeholder="Please select"
        :options="VerificationChannelListOpts"
      />
      <template v-for="(error, idx) in v$.form.verification_channel_id.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>
    <b-form-group :class="[{ 'has-error': v$.form.third_party_account_no.$error }]">
      <label>3rd Party Account Number</label>
      <b-form-input
        v-model="form.third_party_account_no"
        placeholder="Account No"
      />
      <template v-for="(error, idx) in v$.form.third_party_account_no.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <b-form-group :class="[{ 'has-error': v$.form.recipient_name.$error }]">
      <label>Recipient Name</label>
      <b-form-input
        v-model="form.recipient_name"
        placeholder="Recipient Name"
      />
      <template v-for="(error, idx) in v$.form.recipient_name.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <b-form-group :class="[{ 'has-error': v$.form.amount.$error }]">
      <label>Amount</label>
      <vue-numeric
        v-model="form.amount"
        :precision="2"
        :currency="myCurrency"
        class="form-control text-right"
      />
      <template v-for="(error, idx) in v$.form.amount.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <b-form-group :class="[{ 'has-error': v$.form.recipient_reference.$error }]">
      <label>Recipient Reference</label>
      <b-form-input
        v-model="form.recipient_reference"
        placeholder="Recipient Reference"
      />
      <template v-for="(error, idx) in v$.form.recipient_reference.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <b-form-group :class="[{ 'has-error': v$.form.payment_description.$error }]">
      <label>Payment Description</label>
      <b-form-input
        v-model="form.payment_description"
        placeholder="Recipient Reference"
      />
      <template v-for="(error, idx) in v$.form.payment_description.$errors">
        <p
          :key="'error-'+idx"
          class="small text-danger"
        >
          {{ error.$message }}
        </p>
      </template>
    </b-form-group>

    <div class="d-flex justify-content-end mt-3">
      <b-button @click="cancelModal">
        Cancel
      </b-button>
      <b-button
        variant="primary"
        class="ml-1"
        @click="validatePayout"
      >
        Validate
      </b-button>
    </div>
  </div>
</template>

<script>

import vSelect from 'vue-select'
import { useVuelidate } from '@vuelidate/core'
import { minValue, required } from '@vuelidate/validators'

export default {
  components: { vSelect },
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      form: {
        client_identifier: { required },
        verification_channel_id: { required },
        third_party_account_no: { required },
        recipient_name: { required },
        recipient_reference: { required },
        payment_description: { required },
        amount: { required, minValue: minValue(1) },
      },
    }
  },
  data() {
    return {
      result: {},
      form: {
        client_identifier: 'LEANPAY',
        virtual_pool_reference: 'VA-CC408F-161442426909-PAYOUT',
        payout_service_id: null,
        recipient_name: '',
        amount: 0,
        client_redirect_url: 'https://api.leanx.io/pay/thankyou?additional_message=xx&bill_id=xx&extra_param=leanpay-extraneous-param',
        client_callback_url: 'https://api.leanx.io/api/v1/callback-url/call-invoice-payout-encrypt',
        third_party_account_no: '',
        recipient_reference: '',
        payment_description: '',
      },
    }
  },
  computed: {
    VerificationChannelListOpts() {
      return this.$store.getters['general/verificationChannelListOpts']
    },
  },
  methods: {
    async cancelModal() {
      this.form = {
        client_identifier: '',
        virtual_pool_reference: '',
        payout_service_id: null,
        recipient_name: '',
        amount: 0,
        client_redirect_url: '',
        client_callback_url: '',
        third_party_account_no: '',
        recipient_reference: '',
        payment_description: '',
      }
      this.$emit('cancel-payout-clicked', false)
    },
    async validatePayout() {
      const valid = await this.v$.form.$validate()

      if (!valid) {
        return
      }

      const payload = {
        ...this.form,
      }
      await this.$store.dispatch('general/verifyBankAccount', payload).then(res => {
        if (res.data.response_code === 2000) {
          this.result = res.data.data

          // { "Success": true, "Result": { "OrgnThirdPartyBankBICCode": "RHBBMYKL", "OrgnThirdPartyAccountNo": "11232100135692", "CreditorName": "AZRUL ANUAR BIN DUL HAYATI" } }

          const payload2 = {
            ...this.result,
            payoutForm: {
              ...this.form,
              payout_service_id: this.form.verification_channel_id,
            },
          }
          if (this.result) {
            this.$emit('validate-payout-clicked', payload2)
          }
        }
      })
    },
  },
}
</script>

<style>

</style>
